const $get = require('lodash.get')
const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    authMode: ['local'],
    siteId: '65d71730156b452e06b856c5',
    enableExperimentalBlocks: false,
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    // disabledComponents: ['wButtonLike', 'wTagLikeCount','wCardsEngagementLabels'],
    metadatas: {
      name: 'Fréquence Auchan',
      colorPrimary: '#E0001A',
      colorAccent: '#fec400',
      supportEmailAddress: 'support@bubblecast.co',
    },
    analytics: {
      matomo: '46',
    },
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '65df12d5156b45730eb87181',
            i18n: {
              fr: {
                title: `Stories On Air`,
                description: `Les podcasts réservés à l’encadrement`,
              },
            },
            heading: {
              color: '#E0001A',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
            isVisible({ context }) {
              const { state } = context.$store
              const userSegments = $get(state.auth.user, 'protected.segments', [])

              return userSegments.some((segment) => {
                return segment.name === 'encadrement'
              })
            },
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
