<template>
  <div
    class="w-block__pre-heading rounded-lg"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="currentPage === 'default'" class="pa-0 rounded-lg">
      <v-sheet
        ref="sheet"
        color="accent"
        class="pa-3 rounded-lg"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div class="text pl-2 pr-2 pt-1 white--text">
          <strong>
            Votre plateforme de podcasts 100% Auchan 100% internes !
          </strong>
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      showFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'root/currentPage',
    }),
  },

  methods: {},
}
</script>
<style lang="scss" scoped>
.w-block__pre-heading {
  display: flex;
  justify-content: center;
  text-align: center;
  border-radius: 50px;

  .v-sheet {
    border-radius: 7px;
  }
  .text {
    font-size: 20px;
  }
}
</style>
